import axios, { root } from './config/axiosConfig';
import axiosCore from 'axios';

const CancelToken = root.CancelToken;
let source;

export const searchTerm = async (term) => {
  if (source) {
    source.cancel();
  }
  source = CancelToken.source();

  try {
    const res = await axios.get(`/api/search/query`, {
      cancelToken: source.token,
      params: { term },
    });
    return res.data.results;
  } catch (error) {
    throw error;
  }
};

export const searchTags = async (payload) => {
  if (source) {
    source.cancel();
  }
  source = CancelToken.source();

  try {
    const res = await axios.get(`/api/search/filter`, {
      cancelToken: source.token,
      params: payload,
    });
    if (!res?.data) return;

    const { tracks, track_next, keyword_lookup } = res.data;

    return {
      tracks,
      trackNext: tracks?.length ? +track_next : null,
      keywordLookup: keyword_lookup,
    };
  } catch (error) {
    throw error;
  }
};

export const getSimilarByTrack = async ({ trackId, tags }) => {
  const allInclude = tags.inc.map((t) => t.id || t).join(',');
  const allExclude = tags.exc.map((t) => t.id || t).join(',');

  try {
    const res = await axios.get(`/api/search/similar/library`, {
      params: { id: trackId, tags: allInclude, exclude: allExclude },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getSimilarBySpotify = async (id) => {
  try {
    const res = await axios.get(`/api/search/similar/spotify`, {
      params: { id },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const spotifyAuthToken = async () => {
  const SPOTIFY_CLIENT_ID = '066d0d17db4f4993917f2c5b553ccb18';
  const SPOTIFY_CLIENT_SECRET = '4efe26231f90414fa5e5ac317e788acb';

  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
  const body = `grant_type=client_credentials&client_id=${SPOTIFY_CLIENT_ID}&client_secret=${SPOTIFY_CLIENT_SECRET}`;

  try {
    const res = await axiosCore.post(
      'https://accounts.spotify.com/api/token',
      body,
      { headers }
    );
    return res.data.access_token;
  } catch (error) {
    throw error;
  }
};

export const searchKeyword = async (term) => {
  if (source) {
    source.cancel();
  }
  source = CancelToken.source();

  try {
    const res = await axios.get(`/api/search/query`, {
      cancelToken: source.token,
      params: { term },
    });
    return res.data.results;
  } catch (error) {
    throw error;
  }
};

export const searchSpotify = async ({ term, limit = 50 }) => {
  const token = await spotifyAuthToken();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const params = { type: 'track', q: term, limit, market: 'DE' };
  const data = { params, headers };

  try {
    const res = await axiosCore.get('https://api.spotify.com/v1/search', data);
    const items = res.data.tracks?.items.sort((a, b) =>
      a.popularity < b.popularity ? 1 : b.popularity < a.popularity ? -1 : 0
    );
    const list = [];

    for (let item of items) {
      if (list.length === 10) {
        break;
      }
      if (!item.preview_url) {
        continue;
      }
      list.push({
        title: item.name,
        artist: item.artists[0].name,
        image: item.album.images.pop().url,
        id: item.id,
        popularity: item.popularity,
      });
    }
    return list;
  } catch (error) {
    throw error;
  }
};

export const convertKeywords = async (keywords) => {
  try {
    if (source) {
      source.cancel();
    }
    source = CancelToken.source();

    const res = await axios.get(`/api/search/convert`, {
      cancelToken: source.token,
      params: { keywords },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
